window.addEventListener('load', function() {
  // Google AdSense
  const googleAdScript = document.createElement('script');
  googleAdScript.async = true;
  googleAdScript.dataset.adClient = 'ca-pub-7184331995137404';
  googleAdScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
  document.head.appendChild(googleAdScript);

  // Landing strossle tracker
  const strossleScript = document.createElement('script');
  strossleScript.src = 'https://assets.strossle.com/strossle-analytics/1/strossle-analytics.js';
  strossleScript.async = true;
  document.head.appendChild(strossleScript);

  window.strossleAnalyticsQueue = window.strossleAnalyticsQueue || [];
  function strossleAnalytics() { strossleAnalyticsQueue.push(arguments); }
  strossleAnalytics('config', 'deviceStorage', true);

  // Linkedin script
  _linkedin_partner_id = "6736169";
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(_linkedin_partner_id);

  (function(l) {
    if (!l){
      window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
      window.lintrk.q=[]
    }
    var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript";b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);
  })
  (window.lintrk);

  // noscript for linkedin
  const noscriptPixelLinkedin = document.createElement('noscript');
  const imgPixelLinkedin = document.createElement('img');
  imgPixelLinkedin.alt = "linkedin";
  imgPixelLinkedin.height = "1";
  imgPixelLinkedin.width = "1";
  imgPixelLinkedin.style.display = "none";
  imgPixelLinkedin.src = "https://px.ads.linkedin.com/collect/?pid=6736169&fmt=gif";
  noscriptPixelLinkedin.appendChild(imgPixelLinkedin);
  document.body.appendChild(noscriptPixelLinkedin);
});